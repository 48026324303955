<template>
  <div>
    <h1 style="text-align: center; color: #02803f;" class="main-head">Jobs Profit and Loss Report Graphs</h1>
    <div v-if="isLoading" class="d-flex justify-center my-5">
      <v-progress-circular
        indeterminate
        color="primary"
        size="50"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-container fluid>
        <!-- Profit Margin by Category -->
        <v-row>
          <v-col cols="12">
            <div class="job-header">
              <h2>Job #{{ jobNumber }}: {{ jobName }}</h2>
            </div>
          </v-col>
        </v-row>
        <!-- Graphs in a Responsive Row -->
        <v-row dense>
          <v-col cols="12">
            <div class="graph-card">
              <e-charts
                ref="zoomedChart"
                :options="chartOptions2"
                style="height: 400px; width: 100%"
              ></e-charts>
              <v-icon
                small
                color="primary"
                class="zoom-icon"
                @click="openGraph(chartOptions2, 'Profit Margin')"
              >
                mdi-magnify
              </v-icon>

              <!-- Legend Section -->
              <div class="legend-container">
                <div class="legend-item">
                  <div
                    class="legend-color"
                    style="background-color: green"
                  ></div>
                  <span>> 25%: High Profit</span>
                </div>
                <div class="legend-item">
                  <div
                    class="legend-color"
                    style="background-color: blue"
                  ></div>
                  <span>12% - 25%: Moderate Profit</span>
                </div>
                <div class="legend-item">
                  <div class="legend-color" style="background-color: red"></div>
                  <span>< 12%: Low Profit</span>
                </div>
              </div>
            </div>
          </v-col>

          <!-- Overall Metrics -->
          <v-col cols="12">
            <div class="graph-card">
              <e-charts
                ref="zoomedChart"
                :options="chartOptions1"
                style="height: 400px; width: 100%"
              ></e-charts>
              <v-icon
                small
                color="primary"
                class="zoom-icon"
                @click="openGraph(chartOptions1, '')"
              >
                mdi-magnify
              </v-icon>
            </div>
          </v-col>

          <!-- Hours -->
        <v-col cols="12" sm="12" md="6" lg="6">
          <div class="graph-card">
            <e-charts
              ref="zoomedChart"
              :options="chartOptionsHours"
              style="height: 400px; width: 100%"
            ></e-charts>
            <v-icon
              small
              color="primary"
              class="zoom-icon"
              @click="openGraph(chartOptionsHours, '')"
            >
              mdi-magnify
            </v-icon>
          </div>
        </v-col>
        <!-- Expenses Breakdown -->
        <v-col cols="12" sm="12" md="6" lg="6">
          <div class="graph-card">
            <e-charts
              ref="zoomedChart"
              :options="chartOptions3"
              style="height: 400px; width: 100%"
            ></e-charts>
            <v-icon
              small
              color="primary"
              class="zoom-icon"
              @click="openGraph(chartOptions3, '')"
            >
              mdi-magnify
            </v-icon>
          </div>
        </v-col>

          <!-- Labour Hours Breakdown -->
          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="graph-card">
              <e-charts
                ref="zoomedChart"
                :options="chartOptions4"
                style="height: 400px; width: 100%"
              ></e-charts>
              <v-icon
                small
                color="primary"
                class="zoom-icon"
                @click="openGraph(chartOptions4, '')"
              >
                mdi-magnify
              </v-icon>
              <div class="hourly-rate-text">
                Averaged Hourly Rate: ${{
                  labourData.permanentLabourHourlyRate
                }}
                / hr
              </div>
            </div>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="graph-card">
              <e-charts
                ref="zoomedChart"
                :options="chartOptions5"
                style="height: 400px; width: 100%"
              ></e-charts>
              <v-icon
                small
                color="primary"
                class="zoom-icon"
                @click="openGraph(chartOptions5, 'Subcontractor Metrics')"
              >
                mdi-magnify
              </v-icon>
              <div class="hourly-rate-text">
                Hourly Rate: ${{ labourData.subcontactorHourRate }} / hr
              </div>
            </div>
          </v-col>
        </v-row>
    
        <!-- Modal for Zoomed Graph -->
        <v-dialog v-model="zoomedGraph.show" max-width="90%" persistent>
          <v-card style="background-color: #f1efef">
            <v-card-title>
              {{ zoomedGraph.title }}
              <v-spacer></v-spacer>
              <v-btn icon @click="closeGraph">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <e-charts
                ref="zoomedChart"
                :options="zoomedGraph.options"
                style="height: 80vh; width: 100%"
              ></e-charts>
              <!-- Legend Section for Profit Margin -->
              <div
                v-if="zoomedGraph.title === 'Profit Margin'"
                class="legend-container"
              >
                <div class="legend-item">
                  <div
                    class="legend-color"
                    style="background-color: green"
                  ></div>
                  <span>> 25%: High Profit</span>
                </div>
                <div class="legend-item">
                  <div
                    class="legend-color"
                    style="background-color: blue"
                  ></div>
                  <span>12% - 25%: Moderate Profit</span>
                </div>
                <div class="legend-item">
                  <div class="legend-color" style="background-color: red"></div>
                  <span>< 12%: Low Profit</span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";
import "echarts/lib/component/legend";
import "echarts/lib/component/graphic";

export default defineComponent({
  name: "GraphComponent",
  components: {
    ECharts,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
    labourData: {
      type: Object,
      required: true,
    },
    jobNumber: {
      type: [String, Number],
      required: true,
    },
    jobName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      chartOptions1: {}, // Overall metrics
      chartOptions2: {}, // Profit margin with color coding
      chartOptions3: {}, // Expenses breakdown
      chartOptions4: {}, // Labour hours breakdown
      chartOptionsHours: {},
      chartOptions5: {},
      zoomedGraph: {
        show: false,
        options: null,
        title: "",
      },
    };
  },
  watch: {
    reportData: {
      immediate: true,
      handler(newData) {
        if (newData) {
          this.prepareCharts(newData);
          this.isLoading = false;
        }
      },
    },
    labourData: {
      immediate: true,
      handler(newLabourData) {
        if (newLabourData) {
          this.prepareLabourChart(newLabourData);
        }
      },
    },
  },
  methods: {
    prepareCharts(data) {
      // Graph 1: Overall Metrics
      this.chartOptions1 = {
  title: {
    text: "Financial Metrics (Actual vs Planned)",
    left: "center",
    textStyle: {
      fontSize: window.innerWidth <= 600 ? 12 : 18,
      fontWeight: "bold",
      color: "#444444",
    },
  },
  tooltip: {
    trigger: "axis",
    formatter: (params) => {
      let tooltipText = "";
      params.forEach((item) => {
        tooltipText += `${item.marker} ${item.seriesName}: $${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 0,
        }).format(item.value)}<br>`;
      });
      return tooltipText;
    },
  },
  legend: {
    data: ["Actual", "Planned"],
    bottom: "10",
    textStyle: {
      fontSize: window.innerWidth <= 600 ? 10 : 14,
      fontWeight: "bold",
    },
  },
  xAxis: {
    type: "category",
    data: ["Income", "Cost", "Materials Cost", "Net Profit", "Expenses"],
    axisLabel: {
      fontSize: window.innerWidth <= 600 ? 7 : 12,
      rotate: window.innerWidth <= 600 ? 30 : 0,
      margin: window.innerWidth <= 600 ? 10 : 15,
    },
  },
  yAxis: {
    type: "value",
    min: 1,
    axisLabel: {
      fontSize: window.innerWidth <= 600 ? 10 : 14,
      formatter: (value) => `$${new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(value)}`,
    },
  },
  series: [
    {
      name: "Actual",
      type: "bar",
      barMinHeight: 5,
      data: [
        data.Income || "-",
        data.CostOfSales || "-",
        data.TotalMaterials || "-",
        data.NetProfit || "-",
        data.Expenses || "-",
      ],
      itemStyle: { color: "#3b82f6" },
      label: {
        show: true,
        position: "inside",
        fontSize: window.innerWidth <= 600 ? 10 : 14,
        formatter: (params) =>
          `$${new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(params.value)}`,
      },
    },
    {
      name: "Planned",
      type: "bar",
      barMinHeight: 5,
      data: [
        this.labourData.budgetarySaleNumber || "-",
        null,
        this.labourData.budgetaryMaterialsCost || "-",
        this.labourData.budgetaryProfitMargin || "-",
        null,
      ],
      itemStyle: { color: "#f59e0b" },
      label: {
        show: true,
        position: "inside",
        fontSize: window.innerWidth <= 600 ? 10 : 14,
        formatter: (params) =>
          `$${new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(params.value)}`,
      },
    },
  ],
};

this.chartOptionsHours = {
  title: {
    text: "Hours (Actual vs Planned)",
    left: "center",
    textStyle: {
      fontSize: window.innerWidth <= 600 ? 12 : 18,
      fontWeight: "bold",
      color: "#444444",
    },
  },
  tooltip: {
    trigger: "axis",
    formatter: (params) => {
      let tooltipText = "";
      params.forEach((item) => {
        tooltipText += `${item.marker} ${item.seriesName}: ${Math.round(item.value)} hrs<br>`;
      });
      return tooltipText;
    },
  },
  legend: {
    data: ["Actual", "Planned"],
    bottom: "10",
    textStyle: {
      fontSize: window.innerWidth <= 600 ? 10 : 14,
      fontWeight: "bold",
    },
  },
  xAxis: {
    type: "category",
    data: ["Total Hours"],
    axisLabel: {
      fontSize: window.innerWidth <= 600 ? 7 : 12,
    },
  },
  yAxis: {
    type: "value",
    min: 0,
    axisLabel: {
      fontSize: window.innerWidth <= 600 ? 10 : 14,
      formatter: (value) => `${Math.round(value)} hrs`,
    },
  },
  series: [
    {
      name: "Actual",
      type: "bar",
      barMinHeight: 5,
      data: [
        Math.round(
          (parseFloat(this.labourData.labourPermanentHours) || 0) +
          (parseFloat(this.labourData.labourPermanentOvertime1_5xHours) || 0) +
          (parseFloat(this.labourData.labourPermanentOvertime2xHours) || 0) +
          (parseFloat(this.labourData.labourSubcontractorHours) || 0)
        ),
      ],
      itemStyle: { color: "#3b82f6" },
      label: {
        show: true,
        position: "inside",
        fontSize: window.innerWidth <= 600 ? 10 : 14,
        formatter: (params) => `${Math.round(params.value)} hrs`,
      },
    },
    {
      name: "Planned",
      type: "bar",
      barMinHeight: 5,
      data: [Math.round(this.labourData.budgetaryHours || 0)],
      itemStyle: { color: "#f59e0b" },
      label: {
        show: true,
        position: "inside",
        fontSize: window.innerWidth <= 600 ? 10 : 14,
        formatter: (params) => `${Math.round(params.value)} hrs`,
      },
    },
  ],
};

      // Graph 2: Profit Margin with Color Coding
      this.chartOptions2 = {
        title: {
          text: "Profit Margin",
          left: "center",
          textStyle: {
            fontSize: window.innerWidth <= 600 ? 12 : 18,
            fontWeight: "bold",
            color: "#444444",
          },
        },
        tooltip: { trigger: "axis" },
        xAxis: {
          type: "value",
          axisLabel: { formatter: "{value}%" },
        },
        yAxis: {
          type: "category",
          data: ["Profit Margin (%)"],
          axisLabel: {
            rotate: 90,
            fontSize: window.innerWidth <= 600 ? 10 : 14,
            color: "#444444",
            margin: 30,
            fontWeight: "bold",
          },
        },
        series: [
          {
            type: "bar",
            data: [
              {
                value: data.ProfitMargin || 0,
                itemStyle: {
                  color:
                    data.ProfitMargin > 25
                      ? "green"
                      : data.ProfitMargin >= 12 && data.ProfitMargin <= 25
                      ? "blue"
                      : "red",
                },
              },
            ],
            label: {
              show: true,
              position: "insideRight",
              formatter: "{c}%",
            },
          },
        ],
      };

      // Graph 3: Expenses Breakdown
      this.chartOptions3 = {
        title: {
          text: "Expenses Breakdown",
          left: "center",
          textStyle: {
            fontSize: window.innerWidth <= 600 ? 12 : 18, // Responsive font size
            fontWeight: "bold",
            color: "#444444", // Darker color for better visibility
          },
        },
        tooltip: { trigger: "item" },
        legend: { bottom: "10" },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [
              { value: data.TotalLabour || 0, name: "Labour" },
              { value: data.TotalMaterials || 0, name: "Materials" },
              { value: data.Expenses || 0, name: "Overheads" },
            ],
          },
        ],
      };
    },

    prepareLabourChart(labourData) {
      this.chartOptions4 = {
        title: {
          text: "Labour Cost vs Hours Breakdown",
          left: "center",
          textStyle: {
            fontSize: window.innerWidth <= 600 ? 12 : 18, // Responsive font size
            fontWeight: "bold",
            color: "#444444",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" },
        },
        legend: {
          data: ["Cost ($)", "Hours"],
          bottom: 0,
        },
        xAxis: {
          type: "category",
          data: ["Regular Hours", "Overtime (1.5x)", "Overtime (2x)"],
        },
        yAxis: [
          {
            type: "value",
            name: "Cost ($)",
            position: "left",
            axisLabel: { formatter: "${value}" },
          },
          {
            type: "value",
            name: "Hours",
            position: "right",
            axisLabel: { formatter: "{value}" },
          },
        ],
        series: [
          {
            name: "Cost ($)",
            type: "bar",
            yAxisIndex: 0, // Use left axis for cost
            data: [
              parseFloat(
                labourData.permanentLabourHourlyRate *
                  labourData.labourPermanentHours
              ).toFixed(2) || 0,
              parseFloat(
                labourData.permanentLabourHourlyRate *
                  labourData.labourPermanentOvertime1_5xHours
              ).toFixed(2) || 0,
              labourData.permanentLabourHourlyRate *
                labourData.labourPermanentOvertime2xHours || 0,
            ],
            label: {
              show: true,
              position: "inside",
              formatter: (params) =>
                `$${new Intl.NumberFormat("en-US").format(params.value)}`,
              fontWeight: "bold",
            },
            itemStyle: {
              color: "#3b82f6", // Blue bar for costs
            },
          },
          {
            name: "Hours",
            type: "bar",
            yAxisIndex: 1, // Use right axis for hours
            data: [
              labourData.labourPermanentHours || 0,
              labourData.labourPermanentOvertime1_5xHours || 0,
              labourData.labourPermanentOvertime2xHours || 0,
            ],
            label: {
              show: true,
              position: "inside",
              formatter: "{@value} hrs",
            },
            itemStyle: {
              color: "#10b981", // Green bar for hours
            },
          },
        ],
      };
      // New Graph: Subcontractor Metrics Breakdown
      this.chartOptions5 = {
        title: {
          text: "Subcontractor Metrics Breakdown",
          left: "center",
          textStyle: {
            fontSize: window.innerWidth <= 600 ? 12 : 18,
            fontWeight: "bold",
            color: "#444444",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" },
        },
        legend: {
          data: ["Cost ($)", "Hours"],
          bottom: 0,
        },
        xAxis: {
          type: "category",
          data: ["Subcontractor Work"],
        },
        yAxis: [
          {
            type: "value",
            name: "Cost ($)",
            position: "left",
            axisLabel: { formatter: "${value}" },
          },
          {
            type: "value",
            name: "Hours",
            position: "right",
            axisLabel: { formatter: "{value} hrs" },
          },
        ],
        series: [
          {
            name: "Cost ($)",
            type: "bar",
            yAxisIndex: 0, // Use left axis for cost
            data: [labourData.labourSubcontractorCost || 0],
            label: {
              show: true,
              position: "inside",
              formatter: (params) =>
                `$${new Intl.NumberFormat("en-US").format(params.value)}`,
            },
            itemStyle: { color: "#3b82f6" },
          },
          {
            name: "Hours",
            type: "bar",
            yAxisIndex: 1, // Use right axis for hours
            data: [labourData.labourSubcontractorHours || 0],
            label: {
              show: true,
              position: "inside",
              formatter: "{@value} hrs",
            },
            itemStyle: { color: "#10b981" },
          },
        ],
      };
    },

    openGraph(chartOptions, title) {
      this.zoomedGraph.options = { ...chartOptions }; // Clone options for proper rendering
      this.zoomedGraph.title = title;
      this.zoomedGraph.show = true;

      // Delay to ensure modal is fully rendered before resizing
      this.$nextTick(() => {
        setTimeout(() => {
          const chart = this.$refs.zoomedChart;
          if (chart) {
            chart.resize(); // Force resize to calculate dimensions correctly
          }
        }, 200); // Slight delay to let the modal render
      });
    },
    closeGraph() {
      this.zoomedGraph.show = false;
      this.zoomedGraph.options = null; // Clear options to avoid rendering issues
    },
    resizeCharts() {
      // Resize all charts dynamically when the window size changes
      this.$nextTick(() => {
        const chartRefs = this.$refs.zoomedChart;
        if (chartRefs && chartRefs.length) {
          chartRefs.forEach((chart) => chart.resize());
        }
      });
    },
  },
  mounted() {
    // Attach window resize listener
    window.addEventListener("resize", this.resizeCharts);
  },
  beforeDestroy() {
    // Remove window resize listener to prevent memory leaks
    window.removeEventListener("resize", this.resizeCharts);
  },
});
</script>

<style scoped>
h4 {
  text-align: center;
  margin-bottom: 20px;
}

.v-col {
  margin-bottom: 20px; /* Add spacing between graphs */
}

.graph-card {
  position: relative;
  background: #f1efef; /* White background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06); /* 3D shadow */
  padding: 20px; /* Space inside the card */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; /* Smooth transition for hover effect */
  max-width: 100%; /* Ensure it doesn't exceed its parent width */
  overflow: hidden; /* Prevent content from spilling out */
}

.graph-card:hover {
  transform: translateY(-10px); /* Move the card up slightly */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.1); /* Enhance the shadow */
}

.e-charts {
  width: 100% !important; /* Ensure it stretches to the card width */
  height: 100% !important;
}

.zoom-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 5px;
}
.hourly-rate-text {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #444;
}
.legend-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center-align legend items horizontally */
  align-items: center; /* Align items vertically */
  margin-top: 0px;
  gap: 15px; /* Space between legend items */
}

/* Each legend item: color box and text */
.legend-item {
  display: flex;
  align-items: center;
  margin: 0 10px; /* Add horizontal margin for proper spacing */
}

/* Colored rectangle */
.legend-color {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 10px;
}

/* Text alignment */
.legend-item span {
  font-size: 14px;
  font-weight: bold; /* Bold text */
  color: #333;
}
.job-header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #3f3c3c;
}


/* Desktop: Row alignment */
@media screen and (min-width: 600px) {
  .legend-container {
    flex-direction: row; /* Align legend items in a single row */
  }
  /* .main-head {
    font-size: 14px;
  }
  .job-header {
    font-size: 13px;
  } */
}

/* Mobile: Column alignment */
@media screen and (max-width: 599px) {
  .legend-container {
    flex-direction: column; /* Stack legend items vertically */
    align-items: flex-start; /* Align items to the start for readability */
  }
  .main-head {
    font-size: 14px;
  }
  .job-header {
    font-size: 13px;
  }
  .legend-item {
    margin: 5px 0; /* Add vertical spacing between items */
  }

  .legend-color {
    width: 18px; /* Slightly smaller color boxes for mobile */
    height: 18px;
  }

  .legend-item span {
    font-size: 12px; /* Adjust text size for smaller screens */
  }
}
</style>
