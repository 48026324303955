import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

// Views & components
import Home from "../views/dashboard/general/Home.vue";
import UnderDev from "../views/dashboard/general/UnderDev.vue";
import DashboardMain from "../views/dashboard/Main.vue";

// Auth Views & Components
import Account from "../views/auth/Main.vue";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import ForgotPassword from "../views/auth/Forgot.vue";
import Logout from "../views/auth/Logout.vue";
// import RegisterConfirmEmail from "../views/auth/RegisterConfirmEmail.vue";
import ForgotEmail from "../views/auth/ForgotEmail.vue";
// import PendingVerification from "../views/auth/PendingVerification.vue";
// import PendingAdminVerification from "../views/auth/PendingAdminVerification.vue";

import Users from "../views/dashboard/users/Main.vue";
import Notifications from "../views/dashboard/users/Notifications.vue";


// Users Views & Components
import ChangeSettings from "../views/dashboard/users/ChangeSettings.vue";
import ChangeUserSettings from "../views/dashboard/users/ChangeUserSettings.vue";
import ViewActivity from "../views/dashboard/users/ViewActivity.vue";
import ReportGeneration from "../views/dashboard/finance/reportGeneration.vue";
import ActiveJobReport from "../views/dashboard/finance/activeJobReport.vue";
// documentation components
// import Documentation from "../views/dashboard/docs/Documentation.vue";
// import Reference from "../views/dashboard/docs/Reference.vue";

// Pricing Engine Componennts


Vue.use(VueRouter);

const routes = [
  {
    path: "/account",
    name: "Account",
    component: Account,
    children: [
      { path: "login", name: "Login", component: Login },
      // { path: "register", name: "Register", component: Register },
      {
        path: "forgotpassword",
        name: "Forgot Password",
        component: ForgotPassword,
      },
      { path: "logout", name: "Logout", component: Logout },
      // { path: "registerconfirm", name: "RegisterConfirmEmail", component: RegisterConfirmEmail },
      { path: "forgotemail", name: "ForgotEmail", component: ForgotEmail },
      // { path: "pendingverification", name: "PendingVerification", component: PendingVerification },
      // { path: "PendingAdminVerification", name: "PendingAdminVerification", component: PendingAdminVerification },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardMain,
    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
        meta: { page: "Home" },
      },
      // {
      //   path: "underdevelopment",
      //   name: "UnderDev",
      //   component: UnderDev,
      //   meta: { page: "" },
      // },
      {
        path: "report-generation",
        name: "Finance",
        component: ReportGeneration,
        meta: { page: "Job Profit and Loss Report" },
      },
      {
        path: "active-jobs", 
        name: "ActiveJobs",
        component: ActiveJobReport,
        meta: { page: "Active Jobs Report" },
      },
      {
        path: "users",
        name: "Users",
        component: Users,
        children: [
          {
            path: "manageaccount/changesettings",
            name: "ChangeSettings",
            component: ChangeSettings,
            meta: { page: "Edit Account" },
          },
          {
            path: "notifications",
            name: "Notifications",
            component: Notifications,
            meta: { page: "Notifications" },
          },
          {
            path: "manageusers/changeusersettings",
            name: "ChangeUserSettings",
            component: ChangeUserSettings,
            meta: { page: "Users" },
          },
          {
            path: "manageusers/viewactivity",
            name: "ViewActivity",
            component: ViewActivity,
            meta: { page: "User Activity Logs" },
          },
        ],
      },
   

      // {
      //   path: "documentation",
      //   name: "Documentation",
      //   component: Documentation,
      //   meta: { page: "User Manual" },
      // },
      // {
      //   path: "reference",
      //   name: "Reference",
      //   component: Reference,
      //   meta: { page: "Technical Documentation" },
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("setHeading", to.meta.page);
  next();
});

export default router;
