<template>
    <v-container fluid>
      <v-row dense>
        <!-- Total Jobs -->
        <v-col cols="12" sm="4">
          <v-card class="stats-card total-jobs" elevation="4">
            <v-card-title class="d-flex align-center justify-space-between">
              <span>Total Jobs</span>
              <v-icon color="blue">mdi-briefcase</v-icon>
            </v-card-title>
            <v-card-text class="stats-content">
              <h2>{{ totalJobs }}</h2>
              <p>All registered jobs</p>
            </v-card-text>
          </v-card>
        </v-col>
  
        <!-- Active Jobs -->
        <v-col cols="12" sm="4">
          <v-card class="stats-card active-jobs" elevation="4">
            <v-card-title class="d-flex align-center justify-space-between">
              <span>Active Jobs</span>
              <v-icon color="green">mdi-check-circle</v-icon>
            </v-card-title>
            <v-card-text class="stats-content">
              <h2>{{ activeJobs }}</h2>
              <p>Currently active jobs</p>
            </v-card-text>
          </v-card>
        </v-col>
  
        <!-- Inactive Jobs -->
        <v-col cols="12" sm="4">
          <v-card class="stats-card inactive-jobs" elevation="4">
            <v-card-title class="d-flex align-center justify-space-between">
              <span>Inactive Jobs</span>
              <v-icon color="red">mdi-close-circle</v-icon>
            </v-card-title>
            <v-card-text class="stats-content">
              <h2>{{ inactiveJobs }}</h2>
              <p>Jobs that are inactive</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        totalJobs: 0,
        activeJobs: 0,
        inactiveJobs: 0,
      };
    },
    mounted() {
      this.fetchJobStatistics();
    },
    methods: {
      async fetchJobStatistics() {
        try {
          const response = await axios.get("/api/jobs/list");
          this.totalJobs = response.data.length;
          this.activeJobs = response.data.filter((job) => job.IsActive).length;
          this.inactiveJobs = this.totalJobs - this.activeJobs;
        } catch (error) {
          console.error("Error fetching job statistics:", error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .stats-card {
    border-radius: 12px;
    padding: 10px;
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
  }
  .stats-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  .total-jobs {
    border-left: 6px solid blue;
  }
  .active-jobs {
    border-left: 6px solid green;
  }
  .inactive-jobs {
    border-left: 6px solid red;
  }
  .stats-content {
    text-align: center;
  }
  .stats-content h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  .stats-content p {
    font-size: 0.9rem;
    color: gray;
  }
  </style>
  