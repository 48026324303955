<template>
  <v-container class="pa-4" fluid>
    <v-row class="justify-center">
      <v-col cols="12" md="10">
        <v-card elevation="3">
          <v-card-title
            class="text-h5"
            style="justify-content: center; color: #02803f"
          >
            Report To Generate
          </v-card-title>

          <v-card-text>
            <v-form ref="form" @submit.prevent="generateReport">
              <v-row dense style="display: flex; justify-content: center">
                <!-- Start Date -->
                <v-col cols="12" md="3">
                  <v-menu
                    v-model="startMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Start Date"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @input="startMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- End Date -->
                <v-col cols="12" md="3">
                  <v-menu
                    v-model="endMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="End Date"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @input="endMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Checkbox: Show/Hide Job Inputs -->
                <v-col cols="12" md="3">
                  <v-checkbox
                    v-model="showJobsInput"
                    label="Jobs Input"
                    dense
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-alert type="info" dense outlined>
                    <strong>Total Jobs (Active):</strong>
                    {{ jobOptions.length }}
                  </v-alert>
                </v-col>
              </v-row>
              <!-- Jobs Selection & Budget Inputs -->
              <v-row dense v-if="showJobsInput" class="job-table">
                <v-col cols="12">
                  <!-- <v-row class="job-count-header">
    <v-col cols="12" class="header-text" style="text-align: left; font-size: 16px; font-weight: bold;">
      Total Active Jobs: {{ jobOptions.length }}
    </v-col>
  </v-row> -->
                  <v-row class="table-header">
                    <v-col cols="3" class="header-text">Jobs to Track</v-col>
                    <v-col cols="3" class="header-text">Materials Budget</v-col>
                    <v-col cols="3" class="header-text"
                      >Labour Hours Budget</v-col
                    >
                    <v-col cols="3" class="header-text"
                      >Labour Cost Budget</v-col
                    >
                  </v-row>
                </v-col>

                <v-col cols="12" v-for="(job, index) in jobs" :key="index">
                  <v-row>
                    <!-- Job Dropdown -->
                    <v-col cols="3">
                      <v-autocomplete
                        v-model="job.jobNumber"
                        :items="availableJobs(index)"
                        item-value="value"
                        item-text="text"
                        label="Select Job"
                        dense
                        outlined
                        @change="updateJobSelection(index, job.jobNumber)"
                      ></v-autocomplete>
                    </v-col>

                    <!-- Material Budget Input -->
                    <v-col cols="3">
                      <v-text-field
                        v-model="job.materialBudget"
                        label="Material Budget"
                        type="number"
                        dense
                        outlined
                        :disabled="!job.jobNumber"
                      ></v-text-field>
                    </v-col>

                    <!-- Labour Hours Budget Input -->
                    <v-col cols="3">
                      <v-text-field
                        v-model="job.hoursBudget"
                        label="Labour Hours Budget"
                        type="number"
                        dense
                        outlined
                        :disabled="!job.jobNumber"
                      ></v-text-field>
                    </v-col>
                    <!-- Labour Cost Budget Input -->
                    <v-col cols="3">
                      <v-text-field
                        v-model="job.labourBudget"
                        label="Labour Cost Budget"
                        type="number"
                        dense
                        outlined
                        :disabled="!job.jobNumber"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- Generate Report Button -->
              <v-btn class="mt-4 btn" block @click="generateReport"
                >Generate</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- TABS SECTION -->
    <v-row class="mt-5">
      <v-col cols="12">
        <v-tabs v-model="activeTab" centered>
          <v-tab>Report</v-tab>
          <v-tab>Graphs</v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <!-- REPORT TAB -->
          <v-tab-item>
            <v-card elevation="2" class="pa-4">
              <div v-if="isLoading" class="d-flex justify-center my-5">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="50"
                ></v-progress-circular>
              </div>
              <div v-else-if="!showReport" class="d-flex justify-center my-5">
                <!-- <h4>No report generated yet.</h4> -->
              </div>
              <div v-else>
                <h3>Active Jobs Report</h3>
                <p>
                  <b>Gricon Industries Pty Ltd</b><br />183 Bergmans Road
                  Greenmount QLD 4751<br />0417900707
                </p>
                <p><b>Date From:</b> {{ reportData.startDate || "N/A" }}</p>
                <p><b>Date To:</b> {{ reportData.endDate || "N/A" }}</p>
                <p>
                  <b>Generated On:</b> {{ reportData.createdDate || "N/A" }}
                </p>

                <v-simple-table class="mt-3">
                  <template v-slot:default>
                    <thead>
                      <tr style="background-color: #364b59">
                        <th style="color: #fff; text-align: center" colspan="2">
                          Jobs to Track
                        </th>
                        <th style="color: #fff; text-align: center">
                          Material Budget ($)
                        </th>
                        <th style="color: #fff; text-align: center">
                          Actual Material Cost ($)
                        </th>
                        <th style="color: #fff; text-align: center">
                          Labour Budget Hours (Hrs)
                        </th>
                        <th style="color: #fff; text-align: center">
                          Actual Labour Hours (Hrs)
                        </th>
                        <th style="color: #fff; text-align: center">
                          Labour Cost Budget ($)
                        </th>
                        <th style="color: #fff; text-align: center">
                          Actual Labour Cost ($)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(job, index) in reportData.jobs" :key="index">
                        <td
                          style="
                            text-align: center;
                            font-weight: bold;
                            background-color: #6f8095;
                            color: #ffff;
                            padding: 10px;
                          "
                        >
                          Job {{ index + 1 }}:
                        </td>

                        <!-- Job Number Column with Yellow Background -->
                        <td style="text-align: left">
                          {{ job.jobNumber }}
                        </td>
                        <td style="text-align: center">
                          {{ job.manualMaterialsBudget }}
                        </td>
                        <td style="text-align: center">
                          {{ job.actualMaterialsCost }}
                        </td>
                        <td style="text-align: center">
                          {{ job.manualHoursBudget }}
                        </td>
                        <td style="text-align: center">
                          {{ job.actualHours }}
                        </td>
                        <td style="text-align: center">
                          {{ job.manualLabourBudget}}
                        </td>
                        <td style="text-align: center">
                          {{ job.totalLabourCost }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-card>
          </v-tab-item>

          <!-- GRAPHS TAB -->
          <v-tab-item>
            <v-card elevation="2" class="pa-4">
              <GraphComponentActiveJob
                v-if="showReport"
                :reportData="reportData"
              />
              <div v-else class="d-flex justify-center my-5">
                <h4>No graphs available yet.</h4>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row dense v-if="showReport" class="justify-end mt-3">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-4 btn1 small-download-btn"
            small
            v-bind="attrs"
            v-on="on"
          >
            Download Report
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="exportToPDF">
            <v-list-item-title>Download as PDF</v-list-item-title>
          </v-list-item>
          <v-list-item @click="exportToCSV">
            <v-list-item-title>Download as CSV</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <!-- SNACKBAR FOR MESSAGES -->

    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      timeout="3000"
      top
      right
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import GraphComponentActiveJob from "../../../components/GraphComponentActiveJob.vue";

export default {
  data() {
    return {
      startDate: "2019-01-01",
      endDate: "2030-01-01",
      startMenu: false,
      endMenu: false,
      showJobsInput: false,
      jobOptions: [],
      jobs: Array(10).fill().map(() => ({
        jobNumber: null,
        materialBudget: null,
        hoursBudget: null,
        labourBudget: null,
      })),
      isLoading: false,
      reportData: {},
      showReport: false,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
      activeTab: 0,
    };
  },
  components: {
    GraphComponentActiveJob,
  },
  mounted() {
    this.fetchJobs().then(() => {
      this.fetchManualJobs().then(() => {
        this.generateReportAuto(); // Auto-generate report on page load
      });
    });
  },
  methods: {
    async fetchJobs() {
      try {
        const response = await axios.get("/api/jobs/list");
        const activeJobs = response.data.filter((job) => job.IsActive);
        this.jobOptions = activeJobs.map((job) => ({
          value: job.Number,
          text: `${job.Number} - ${job.Name}`,
        }));
        this.validateJobs();
      } catch (error) {
        console.error("Error fetching jobs:", error);
        this.showSnackbar("Failed to fetch job list.", "red");
      }
    },
    async fetchManualJobs() {
      try {
        const response = await axios.get("/api/jobs/manual-job-budgets");
        console.log("Manual Job Budgets Response:", response.data);

        if (!response.data || response.data.length === 0) {
          this.showSnackbar("No job budgets found. Please add jobs.", "blue");
          // Ensure jobs array remains 10 slots even if no data
          this.jobs = Array(10).fill().map(() => ({
            jobNumber: null,
            materialBudget: null,
            hoursBudget: null,
            labourBudget: null,
          }));
          return;
        }

        // Always initialize jobs array with 10 empty slots
        const tempJobs = Array(10).fill().map(() => ({
          jobNumber: null,
          materialBudget: null,
          hoursBudget: null,
          labourBudget: null,
        }));

        // Map manual jobs to the jobs array based on position
        response.data.forEach((job) => {
          const position = job.position;
          if (position >= 0 && position < 10) {
            tempJobs[position] = {
              jobNumber: job.jobNumber,
              materialBudget: job.materialBudget || null,
              hoursBudget: job.hoursBudget || null,
              labourBudget: job.labourBudget || null,
            };
          }
        });

        this.jobs = tempJobs;

        // Validate after loading manual jobs
        this.validateJobs();
        console.log("Loaded Manual Jobs:", this.jobs);
      } catch (error) {
        console.error("Error fetching job budgets:", error);
        this.showSnackbar("Failed to fetch job budgets.", "red");
        // Ensure jobs array remains 10 slots even on error
        this.jobs = Array(10).fill().map(() => ({
          jobNumber: null,
          materialBudget: null,
          hoursBudget: null,
          labourBudget: null,
        }));
      }
    },
    validateJobs() {
      const validJobNumbers = this.jobOptions.map((job) => job.value);
      this.jobs = this.jobs.map((job) => {
        if (job.jobNumber && !validJobNumbers.includes(job.jobNumber)) {
          return {
            jobNumber: null,
            materialBudget: null,
            hoursBudget: null,
            labourBudget: null,
          };
        }
        return job;
      });
    },
    async generateReport() {
      this.isLoading = true;
      this.showSnackbar("Generating report...", "blue");
      try {
        const payload = {
          jobNumbers: this.jobs.map((job) => job.jobNumber).filter(Boolean),
          manualInputs: this.jobs.filter((job) => job.jobNumber),
        };
        const response = await axios.post("/api/jobs/active-jobs", payload, {
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
          },
        });
        console.log("Generated Report:", response.data);
        this.reportData = response.data;
        this.showReport = true;
        this.showSnackbar("Report generated successfully!", "green");
      } catch (error) {
        this.showSnackbar("Error generating report", "red");
      } finally {
        this.isLoading = false;
      }
    },
    async generateReportAuto() {
      this.isLoading = true;
      try {
        const payload = {
          jobNumbers: this.jobs.map((job) => job.jobNumber).filter(Boolean),
          manualInputs: this.jobs.filter((job) => job.jobNumber),
        };
        const response = await axios.post("/api/jobs/active-jobs", payload, {
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
          },
        });
        console.log("Generated Report:", response.data);
        this.reportData = response.data;
        this.showReport = true;
      } catch (error) {
        this.showSnackbar("Error generating report", "red");
      } finally {
        this.isLoading = false;
      }
    },
    showSnackbar(message, color) {
      this.snackbarMessage = message;
      this.snackbarColor = color;
      this.snackbar = true;
    },
    updateJobSelection(index, jobNumber) {
      if (!jobNumber) {
        this.jobs[index] = {
          jobNumber: null,
          materialBudget: null,
          hoursBudget: null,
          labourBudget: null,
        };
      }
      // Ensure jobs array always has 10 slots
      this.jobs = [...this.jobs];
    },
    availableJobs(index) {
      const selectedJobs = this.jobs
        .map((job, i) => (i !== index ? job.jobNumber : null))
        .filter(Boolean);

      console.log("Selected Jobs (excluding current index):", selectedJobs);
      console.log("All Available Jobs:", this.jobOptions);

      const filteredJobs = this.jobOptions.filter(
        (job) => !selectedJobs.includes(job.value)
      );

      console.log(`Filtered Jobs for index ${index}:`, filteredJobs);
      return filteredJobs;
    },
    toggleJobsInput() {
      if (this.showJobsInput) {
        this.validateJobs();
      }
    },
    //  PDF EXPORT FUNCTION (Using jsPDF)
    exportToPDF() {
      if (!this.showReport) return;

      const doc = new jsPDF();
      doc.text("Gricon Industries Pty Ltd - Active Jobs Report", 10, 10);
      doc.text("183 Bergmans Road, Greenmount QLD 4751", 10, 20);
      doc.text("Phone: 0417900707", 10, 30);
      doc.text(`Date From: ${this.reportData.startDate}`, 10, 40);
      doc.text(`Date To: ${this.reportData.endDate}`, 10, 50);
      doc.text(
        `Generated On: ${
          this.reportData.createdDate || new Date().toLocaleString()
        }`,
        10,
        60
      );

      const headers = [
        [
          "Job #",
          "Material Budget ($)",
          "Actual Material Cost ($)",
          "Labour Budget (Hrs)",
          "Actual Labour Hours (Hrs)",
          "Labour Cost Budget ($)",
          "Actual Labour Cost ($)",
        ],
      ];

      const data = this.reportData.jobs.map((job, index) => [
        `Job ${index + 1}: ${job.jobNumber}`,
        job.manualMaterialsBudget,
        job.actualMaterialsCost,
        job.manualHoursBudget,
        job.actualHours,
        job.manualLabourBudget,
        job.totalLabourCost,
      ]);

      doc.autoTable({ startY: 70, head: headers, body: data });
      const timestamp = new Date()
        .toISOString()
        .replace(/:/g, "-")
        .split(".")[0]; // Removes invalid ":" from filename
      doc.save(`Active_Jobs_Report_${timestamp}.pdf`);
    },

    // CSV EXPORT FUNCTION (Using Plain JavaScript)
    exportToCSV() {
      if (!this.showReport || !this.reportData || !this.reportData.jobs) {
        this.snackbarMessage = "No report available to download.";
        this.showSnackbar = true;
        return;
      }

      // Prepare CSV content
      const csvContent = [];

      // Header section
      csvContent.push(["Gricon Industries Pty Ltd - Active Jobs Report"]);
      csvContent.push([]);
      csvContent.push(["183 Bergmans Road, Greenmount QLD 4751"]);
      csvContent.push(["Phone: 0417900707"]);
      csvContent.push([]);
      csvContent.push(["Date From:", this.reportData.startDate || "N/A"]);
      csvContent.push(["Date To:", this.reportData.endDate || "N/A"]);
      csvContent.push([
        "Generated On:",
        this.reportData.createdDate || new Date().toLocaleString(),
      ]);
      csvContent.push([]);
      csvContent.push([]);

      // Table Header
      csvContent.push([
        "Jobs To Track",
        "Material Budget ($)",
        "Actual Material Cost ($)",
        "Labour Budget Hours (Hrs)",
        "Actual Labour Hours (Hrs)",
        "Labour Cost Budget ($)",
        "Actual Labour Cost ($)",
      ]);

      // Table Data (Loop through Jobs)
      this.reportData.jobs.forEach((job, index) => {
        csvContent.push([
          `Job ${index + 1}: ${job.jobNumber}`,
          job.manualMaterialsBudget || "0.00",
          job.actualMaterialsCost || "0.00",
          job.manualHoursBudget || "0.00",
          job.actualHours || "0.00",
          job.manualLabourBudget || "0.00",
          job.totalLabourCost || "0.00"
        ]);
      });

      csvContent.push([]);

      // Convert array to CSV format
      const csvData = csvContent
        .map((row) => row.map((cell) => `"${cell}"`).join(",")) // Wrap each cell in quotes for safety
        .join("\n");

      // Download CSV file
      const blob = new Blob([csvData], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      const timestamp = new Date()
        .toISOString()
        .replace(/:/g, "-")
        .split(".")[0];
      link.download = `Active_Jobs_Report_${timestamp}.csv`;
      link.click();
    },
  },
};
</script>

<style scoped>
.text-h5 {
  font-size: 1.25rem;
  font-weight: 500;
}

/* Button Styles */
.btn {
  background: linear-gradient(to bottom, #02803f, #00a651, #02cc64, #8ad1ac);
  color: #fff;
}

/* Table Styles */
.job-table {
  border: 2px solid #000;
  padding: 10px;
  background: #f9f9f9;
}

.table-header {
  background: #364b59;
  color: #fff;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

.header-text {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

/* Yellow Inputs (To match the image) */
.btn1 {
  background-color: black !important;
  color: #fff;
}
.small-download-btn {
  width: auto !important; /* Ensure the button adjusts to content width */
  padding: 0 10px !important; /* Add some padding */
  font-size: 12px; /* Adjust the font size for a smaller button */
  background-color: black !important; /* Button background color */
  color: white !important; /* Button text color */
}
</style>
