<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="header-title">
          <h1>Active Job Tracking</h1>
        </div>
      </v-col>
    </v-row>

    <!-- Loop through all jobs in reportData -->
    <v-row dense v-for="(job, index) in reportData.jobs" :key="index">
      <v-col cols="12">
        <!-- <div class="job-header">
            <h2>Job #{{ job.jobNumber }}: {{ job.jobName }}</h2>
          </div> -->
      </v-col>

      <!-- Actual vs Budget Graph -->
      <v-col cols="12">
        <div class="graph-card">
          
          <e-charts
            ref="zoomedChart"
            :options="getChartOptions(job)"
            style="height: 400px; width: 100%"
          ></e-charts>
          <div class="graph-title">Actual vs Budget</div>
          <v-icon
            small
            color="primary"
            class="zoom-icon"
            @click="openGraph(getChartOptions(job), job.jobName)"
          >
            mdi-magnify
          </v-icon>
          
        </div>
      </v-col>
    </v-row>

    <!-- Zoomed Graph Modal -->
    <v-dialog v-model="zoomedGraph.show" max-width="90%" persistent>
      <v-card>
        <v-card-title>
          {{ zoomedGraph.title }}
          <v-spacer></v-spacer>
          <v-btn icon @click="closeGraph">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <e-charts
            ref="zoomedChart"
            :options="zoomedGraph.options"
            style="height: 80vh; width: 100%"
          ></e-charts>       
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";
import "echarts/lib/component/legend";

export default defineComponent({
  name: "GraphComponentActiveJob",
  components: {
    ECharts,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      zoomedGraph: {
        show: false,
        options: null,
        title: "",
      },
    };
  },
  methods: {
    getChartOptions(job) {
      const isMobile = window.innerWidth < 600;
      const isMobileLandscape = window.innerWidth < 768;
      return {
        title: {
          text: `${job.jobNumber} ~ ${job.jobName}`,
          left: "center",
          textStyle: {
            fontSize: isMobile ? 10 : 16,
            fontWeight: "bold",
            color: "#444444",
          },
        },
        tooltip: { trigger: "axis" },
        legend: isMobile ? {
          
          data: [
            "Hours Actual",
            "Hours Budget",
            "Labour Actual Cost",
            "Labour Budget",
            "Materials Actual",
            "Materials Budget"
          ],
          bottom: 0,
          itemGap: 1,
          textStyle: { fontSize: 7 },
          padding: [10, -5, 2, -5],
        }
         : {
          data: [
            "Hours Actual",
            "Hours Budget",
            "Labour Actual Cost",
            "Labour Budget",
            "Materials Actual",
            "Materials Budget"
          ],
          left: 0,
          orient: "vertical",
          itemGap: 25,
          textStyle: { fontSize: 12 },
          padding: [100, 0, 10, 0],
        },
        grid: {
          left: isMobile ? "5%" : "10%",
          right: isMobile ? "12%" : "15%",
          bottom: isMobile ? "12%" : "15%",
          containLabel: true,
        },

        yAxis: {
          type: "category",
          data: [""], // Single row to align all bars
          axisLabel: {
            fontSize: isMobile ? 10 : 12,
            rotate: isMobile || isMobileLandscape ? 90 : 0,
            align: isMobile ? "center" : "right",
          },
        },

        // **Two X-Axes (Primary: Hours, Secondary: Material Cost & Labour Cost)**
        xAxis: [
          {
            type: "value",
            name: "Hours",
            position: "top",
            axisLabel: { fontSize: isMobile ? 8 : 12, formatter: "{value} hrs" },
          },
          {
            type: "value",
            name: "Cost ($)",
            position: "bottom",
            axisLabel: {
              fontSize: isMobile ? 8 : 12,
              formatter: (value) => `$${new Intl.NumberFormat("en-US").format(value)}`,
            },
          },
        ],

        series: [
          {
            name: "Hours Actual",
            type: "bar",
            barGap: 0.1,
            barWidth: isMobile ? "10%" : "15%", // Ensuring all bars have the same size
            data: [job.actualHours],
            itemStyle: { color: "#A02B93" },
            label: { show: true, position: "right",  fontSize: isMobile ? 8 : 12, formatter: (params) => `${Math.round(params.value)} hrs` },
            xAxisIndex: 0,
          },
          {
            name: "Hours Budget",
            type: "bar",
            barGap: 0.1,
            barWidth: isMobile ? "10%" : "15%",
            data: [job.manualHoursBudget],
            itemStyle: { color: "#196B24" },
            label: { show: true, position: "right",  fontSize: isMobile ? 8 : 12, formatter: (params) => `${Math.round(params.value)} hrs` },
            xAxisIndex: 0,
          },
          {
            name: "Labour Actual Cost",
            type: "bar",
            barGap: 0.1,
            barWidth: isMobile ? "10%" : "15%",
            data: [job.totalLabourCost],
            itemStyle: { color: "#4B0082" }, // Indigo
            label: { show: true, position: "right",  fontSize: isMobile ? 8 : 12, formatter: (params) => `$${Math.round(params.value)}` },
            xAxisIndex: 1,
          },
          {
            name: "Labour Budget",
            type: "bar",
            barGap: 0.1,
            barWidth: isMobile ? "10%" : "15%",
            data: [job.manualLabourBudget],
            itemStyle: { color: "#FFA500" }, // Orange
            label: { show: true, position: "right",  fontSize: isMobile ? 8 : 12, formatter: (params) => `$${Math.round(params.value)}` },
            xAxisIndex: 1,
          },
          {
            name: "Materials Actual",
            type: "bar",
            barGap: 0.1,
            barWidth: isMobile ? "10%" : "15%",
            data: [job.actualMaterialsCost],
            itemStyle: { color: "#0F9ED5" },
            label: { show: true, position: "right",  fontSize: isMobile ? 8 : 12, formatter: (params) => `$${Math.round(params.value)}`},
            xAxisIndex: 1,
          },
          {
            name: "Materials Budget",
            type: "bar",
            barGap: 0.1,
            barWidth: isMobile ? "10%" : "15%",
            data: [job.manualMaterialsBudget],
            itemStyle: { color: "#E97132" },
            label: { show: true, position: "right",  fontSize: isMobile ? 8 : 12, formatter: (params) => `$${Math.round(params.value)}`},
            xAxisIndex: 1,
          },      
        ],
      };
    },

    openGraph(options, title) {
      this.zoomedGraph.options = { ...options };
      // this.zoomedGraph.title = title;
      this.zoomedGraph.show = true;
      // Delay to ensure modal is fully rendered before resizing
      this.$nextTick(() => {
        setTimeout(() => {
          const chart = this.$refs.zoomedChart;
          if (chart) {
            chart.resize(); // Force resize to calculate dimensions correctly
          }
        }, 200); // Slight delay to let the modal render
      });
    },
    closeGraph() {
      this.zoomedGraph.show = false;
    },
    resizeCharts() {
      // Resize all charts dynamically when the window size changes
      this.$nextTick(() => {
        const chartRefs = this.$refs.zoomedChart;
        if (chartRefs && chartRefs.length) {
          chartRefs.forEach((chart) => chart.resize());
        }
      });
    },
  },
});
</script>

<style scoped>
.header-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #02803f;
}

.job-header {
  text-align: center;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #444444;
}

.graph-card {
  position: relative;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.graph-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.zoom-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: white;
  border-radius: 50%;
  padding: 5px;
}
.graph-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #444;
}
@media screen and (max-width: 599px) {
  .header-title {
    font-size: 14px;
  }
  .graph-title {
  font-size: 14px;
}
}
</style>
